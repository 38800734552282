a:-webkit-any-link {
  color: inherit;
}

.account-form {
  padding: 30px !important;
}

.campaign-form {
  max-width: 60% !important;
  margin: auto !important;
}

.input-field {
  padding-top: 17px !important;
  padding-left: 25px !important;
}

.text-center {
  text-align: center;
}